import React,{Fragment, useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import Footer from './Footer'
import { login, texts } from './UserFunctions'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser'
import jwt_decode from 'jwt-decode'

function Landing() {

    const history = useHistory();
    const location = useLocation();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [text, setText] = useState('')
    const [direction, changeDirection] = useState(false)
    const [loading, setLoading] = useState(true)
    const { t } = useTranslation();


    useEffect(() => {
         
        if(localStorage.getItem("usertoken")!=null && localStorage.getItem("usertoken")!=''){
            history.push(`/welcome`)
        }
        
        

        texts()
        .then(async userData => {

            if(userData)
            {
                if (localStorage.getItem('language') === 'he')
                {
                    setText(userData.data[2].body_he)
                    changeDirection(false)
                }
                else
                {
                    setText(userData.data[2].body_en)
                    changeDirection(true)
                }
                setLoading(false)
            }

        })
        .catch((error) => { 
            console.log("Error!")
        });


    },[text]);

    const handleSubmit = async (event) => {
        event.preventDefault();

        const user = {
            email,password
        }

        login(user)
        .then(userData => {

            if(userData)
            {
                const token = localStorage.usertoken
                const decoded = jwt_decode(token)

                toast.info(t('loginSuccess'))
                setTimeout(() => {
                    if(decoded.super==1)
                        history.push(`/super`)
                    else
                        history.push(`/welcome`)
                }, 3000)
            }
            else
            toast.error(t('invalidLogin'))

        })
        .catch((error) => { 
        toast.error(t('invalidLogin'))
        });

    }


    if(loading)
    {
        return(
            <div className="Loading">
            <div className="wrapper">
                <div className="container">
                    <div className="row">
                    <div className="col-12 text-center">
                        <div className="logo wow animate__fadeInLeft animate__animated">
                        <a href><img src="resources/images/logo.png" /></a>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="welcomeback"  style={{paddingTop:'10%'}}>
                    <div className="container">
                    <div className="row ">
                        <div className="col-12 text-center">
                            <div className="loading_2 mt-4">
                                <div className="lds-ripple"><div /><div /></div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
        )
    }else{
    return (
    
    <div className="Login">
        <div className="wrapper">
            <div className="container">
            <div className="row">
                <div className="col-12 text-center">
                <div className="logo wow animate__fadeInLeft animate__animated">
                    <a href><img src="resources/images/logo.png" /></a>
                </div>
                </div>
            </div>
            </div>
            <div className="welcomeback">
            <div className="container">
                <div className="row ">
                <div className="col-12 text-center">
                    <div className="text_desc wow animate__fadeInLeft animate__animated">
                    <p>
                    {text.length>1?
                        parse(text)
                    :null
                    }
                    </p>
                    </div>
                    <form className="mb-5" onSubmit={handleSubmit}>
                    <div className="row justify-content-center">
                        <div className="form-group col-12 col-sm-10 col-md-6">
                        <div className={direction ? 'animated_input_en' : 'animated_input'}>
                            <input type="text" name="email" className="form-control"
                            onChange={e => setEmail(e.target.value)}
                            required />
                            <label>{t('email')}</label>
                        </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="form-group col-12 col-sm-10 col-md-6">
                        <div className={direction ? 'animated_input_en' : 'animated_input'}>
                            <input type="password" name="password" className="form-control" 
                            onChange={e => setPassword(e.target.value)}
                            required />
                            <label>{t('password')}</label>
                        </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-6">
                            <button type="submit" className="start_assesment">{t('startTest')}</button>
                            &nbsp;
                            <Link to="/signup" className="start_assesment">{t('createAcc')}</Link>
                        </div>
                    </div>
                    </form>
                </div>
                </div>
            </div>
            </div>
        </div>
        <Footer />
        {
        direction?
            <ToastContainer />
        :
            <ToastContainer rtl/>
        }

    </div>
    )
    }
}

export default Landing
