import React,{useEffect} from 'react'
import Footer from './Footer'
import { useHistory } from 'react-router';
import { client_answers} from './UserFunctions'
import jwt_decode from 'jwt-decode'


function Loading() {

    const history = useHistory();

    useEffect(() => {
         
        if(localStorage.getItem("usertoken")==null || localStorage.getItem("usertoken")==''){
            history.push(`/home`)
          }

          
        const token = localStorage.usertoken
        const decoded = jwt_decode(token)

          const idToPass = {
            userId : decoded.id,
        }
        


        client_answers(idToPass)
        .then(userData => {

            var temp = userData.data.number_q

            if(temp!=79)
            {
                history.push(`/question`)
            }
            
            

        })
        .catch((error) => { 
            console.log("Error! Something went wrong")
        });
        
        setTimeout(function() {
         //   history.push(`/result`)
        }.bind(this), 10000)


        
    });

    return (
        <div className="Loading">
            <div className="wrapper">
                <div className="container">
                    <div className="row">
                    <div className="col-12 text-center">
                        <div className="logo wow animate__fadeInLeft animate__animated">
                        <a href><img src="resources/images/logo.png" /></a>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="welcomeback">
                    <div className="container">
                    <div className="row ">
                        <div className="col-12 text-center">
                        <div className="text_desc wow animate__fadeInLeft animate__animated wow animate__fadeInLeft animate__animated">
                            <p>המערכת החכמה שלנו, מרלין, תעבד כעת את התשובות שסיפקתם ויחד עם צוות הקורס תתאים לכם בני/בנות זוג ככל שקיימים כאלה במאגר.
המשיכו בקורס כרגיל ואנחנו ניידע אתכם לגבי ההתאמות הנכונות עבורכם לכשיהיו.</p>
                        </div>
                        <div className="loading_2 mt-4">
                            <div className="lds-ripple"><div /><div /></div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Loading
