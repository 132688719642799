import axios from 'axios'


const url = "https://lovush.herokuapp.com"
// const url = "http://localhost:5000"

export const login = user => {
return axios
    .post(`${url}/clients/login`, {
    email: user.email,
    password: user.password
    })
    .then(response => {
    localStorage.setItem('usertoken', response.data.token)
    return response.data
    })
    .catch(err => {
    console.log(err)
    })
}

export const signup = user => {
    return axios
        .post(`${url}/clients/signup`, user)
        .then(response => {
            return response.data
        })
        .catch(err => {
            console.log(err)
        })
    }
      
  

export const client_answers = user => {
return axios
    .post(`${url}/clientanswers/get`, {
    id: user.userId
    })
    .then(response => {
    return response.data
    })
    .catch(err => {
    console.log(err)
    })
}
  
export const update_gender = user => {
return axios
    .post(`${url}/clientanswers/post`, user)
    .then(response => {
    return response.data
    })
    .catch(err => {
    console.log(err)
    })
}


export const update_client_answers = user => {
return axios
    .post(`${url}/clientanswers/update`, user)
    .then(response => {
    return response.data
    })
    .catch(err => {
    console.log(err)
    })
}
  


export const questions = user => {
return axios
    .post(`${url}/questions/get`, {
        user
    })
    .then(response => {
    return response.data
    })
    .catch(err => {
    console.log(err)
    })
}

export const answers = data => {
    return axios
        .post(`${url}/answers/get`, {
            id:data.id,
            sex:data.sex
        })
        .then(response => {
        return response.data
        })
        .catch(err => {
        console.log(err)
        })
    }

export const getResult = data => {
    return axios
        .post(`${url}/result/get`, {
            id:data.userId,
            lang: data.lang
        })
        .then(response => {
        return response.data
        })
        .catch(err => {
        console.log(err)
        })
    }

export const texts = data => {
    return axios
        .get(`${url}/texts/get`)
        .then(response => {
        return response.data
        })
        .catch(err => {
        console.log(err)
        })
    }