import React,{useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import Footer from './Footer'
import { useHistory } from 'react-router';
import { update_gender } from './UserFunctions'
import jwt_decode from 'jwt-decode'
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';


const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '25ch',
      },
    },
  }));

  
function Gender() {

    const classes = useStyles();

    const history = useHistory();
    const [clientID, setClient] = useState('')

    useEffect(() => {
         
        if(localStorage.getItem("usertoken")==null || localStorage.getItem("usertoken")==''){
            history.push(`/home`)
        }

        const token = localStorage.usertoken
        const decoded = jwt_decode(token)

        if(decoded.super==0)
            history.push(`/home`)
        
    });

    const handleSubmit = async (event) => {
        event.preventDefault();

        
        localStorage.setItem('clientID', clientID)
        history.push(`/superesult`)

          
    }

    return (
        <div className="before_img Gender">
            <div className="wrapper">
                <div className="container">
                    <div className="row">
                    <div className="col-12 text-center">
                        <div className="logo wow animate__fadeInLeft animate__animated">
                        <a href><img src="resources/images/logo.png" /></a>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="container">
                    <form onSubmit={handleSubmit}>
                    <div className="row">
                    <div className="col-12">
                        <div className="text_desc text-center wow animate__fadeInLeft animate__animated">
                  
                        <br></br>
                        <br></br>
                        <br></br>
                        </div>
                        <div className="custom_gender text-center wow animate__fadeInLeft animate__animated">
                        <form className={classes.root} noValidate autoComplete="off">
                            <TextField id="outlined-basic" label="Client ID" name="clientID"
                             onChange={e => setClient(e.target.value)}
                             value={clientID}
                             variant="outlined" />
                        </form>
                        </div>
                        <div className="text_desc text-center m-0 wow animate__fadeInLeft animate__animated">
                        <br></br>
                        </div>
                        <div className="smallbtns custom_btn text-center" style={{direction:'ltr'}}>
                            <button type="submit" class="smallbtnscustom">Continue</button>
                            <Link to="/logout">Go Out</Link>
                        </div>
                    </div>
                    </div>
                    </form>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Gender
