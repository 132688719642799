import React,{Fragment, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import Footer from './Footer'
import jwt_decode from 'jwt-decode'
import { useHistory } from 'react-router';
import { client_answers, getResult, texts} from './UserFunctions'
import parse from 'html-react-parser'
import { ThemeProvider } from "styled-components";
import printJs from 'print-js'
import html2canvas from 'html2canvas'
import { useTranslation } from 'react-i18next';


import {
  Container,
  IntersectionObserver,
  ProgressBar,
  theme,
} from "./Ui";


import {
    EmailShareButton,
    FacebookShareButton,
    WhatsappShareButton
  } from "react-share";



function Result() {

    const history = useHistory();

    const [email,setEmail] = useState('')
    const [result, setResult] = useState('')
    const [loading, setLoading] = useState(false)
    const [scores,setScores] = useState([])
    const [scoresEn,setScoresEn] = useState([])
    const [colors,setColors] = useState([])
    const [text,setText] = useState('')
    const [direction, setDirection] = useState('')
    const [position, setPosition] = useState('')
    const [flag, setFlag] = useState(false)
    const { t } = useTranslation();

    const shareUrl = "https://academy.Lovush.com/courses/am-i-a-relationship-master"


    

    useEffect(() => {

        if(localStorage.getItem("usertoken")==null || localStorage.getItem("usertoken")==''){
            history.push(`/home`)
        }

        // if(!window.location.hash) {
        //     window.location = window.location + '#loaded';
        //     window.location.reload();
        // }

          const token = localStorage.usertoken
          const decoded = jwt_decode(token)

          const fetchData = () => {
            setEmail(decoded.email)
          }
          fetchData();
        
            texts()
            .then(async userData => {

                if(userData)
                {
                    if (localStorage.getItem('language') === 'he')
                    {
                        await setText(userData.data[4].body_he)
                        setDirection('rtl')
                        setPosition('right')
                        setFlag(false)
                    }
                    else
                    {
                        await setText(userData.data[4].body_en)
                        setDirection('ltr')
                        setPosition('left')
                        setFlag(true)
                    }
                }

            })
            .catch((error) => { 
                console.log("Error!")
            });

            const idToPass = {
                userId : decoded.id,
                lang: localStorage.getItem('language')
            }
            


            client_answers(idToPass)
            .then(userData => {

                var temp = userData.data.number_q

                if(temp!=79)
                {
                    history.push(`/question`)
                }
                
                

            })
            .catch((error) => { 
                console.log("Error! Something went wrong")
            });


            getResult(idToPass)
            .then(async userData => {

                await setResult(userData.output)
                await setColors({
                    "25": "#ff0000",
                    "50": "#ff8c00",
                    "75": "#FFFF00",
                    "100": "#008000"
                })
                await setScores({
                    "100": "ההישג שלך: הולם",
                    "75": "ההישג שלך: סביר",
                    "50": "ההישג שלך: לא מספק",
                    "25": "ההישג שלך: בלתי הולם"
                })
                await setScoresEn({
                    "100": "Your Achievement: Appropriate",
                    "75": "Your Achievement: Reasonable",
                    "50": "Your Achievement: Not Satisfactory",
                    "25": "Your Achievement: Inappropriate"
                })
                

            })
            .catch((error) => { 
                console.log("Error! Something went wrong")
            });

            
            if(!loading){
                setTimeout(function() {
                    setLoading(true)
                }.bind(this), 10000)
            }
       
        
     
    },[email])

   

   


    const print = async (event) => {
        html2canvas(document.body).then(function(canvas) {
            var base64URL = canvas.toDataURL('image/jpeg').replace('image/jpeg', 'image/octet-stream');
            printJs(base64URL, 'image');
        });
    }

    if(!loading){
    return (
        
        <div className="Loading">
            <div className="wrapper">
                <div className="container">
                    <div className="row">
                    <div className="col-12 text-center">
                        <div className="logo wow animate__fadeInLeft animate__animated">
                        <a href><img src="resources/images/logo.png" /></a>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="welcomeback"  style={{paddingTop:'10%'}}>
                    <div className="container">
                    <div className="row ">
                        <div className="col-12 text-center">
                        <div className="text_desc wow animate__fadeInLeft animate__animated wow animate__fadeInLeft animate__animated">
                                <p>
                                    {text.length>0?
                                        parse(text)
                                    :null}
                                </p>
                        </div>
                        <div className="loading_2 mt-4">
                            <div className="lds-ripple"><div /><div /></div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )}else{
    return(
        
        <div style={{direction:'ltr'}} className="Result">
            <div className="wrapper">
                <div className="container">
                    <div className="row">
                    <div className="col-12 text-center">
                        <div className="logo wow animate__fadeInLeft animate__animated">
                        <a href><img src="resources/images/logo.png" /></a>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="welcomeback" style={{paddingTop:'30px'}}>
                    <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-12 text-center">
                        <div className="progressbar_box">
                            <div className="row">
                            <div className="col-12">
                                <div className="user_site" style={{paddingTop:'10px'}}>
                                    <h4>{t('resultTitle')}</h4>
                                </div>
                                <div className="user_site" style={{paddingTop:'10px'}}>
                                {flag ? 
                                <h6>{t('resultReport')} {email} </h6>
                                :
                                <h6>{email} {t('resultReport')}</h6>
                                }
                                {result[0] > 0 && result[0] < 11 ? 
                                <>
                                    {t('accuracy0')}
                                    {result[0]}
                                    {t('accuracy1')}
                                    {t('no_much')}
                                </>
                                :null}
                                {result[0] > 10? 
                                <>
                                    {t('accuracy0')}
                                    {result[0]}
                                    {t('accuracy1')}
                                    {t('much')}
                                </>
                                :null}
                                <br></br>
                                <br></br>
                                </div>
                            </div>
                            {result.length > 0?
                            <div className="col-12 col-sm-8 col-md-6">
                                
                                <div className="myProgress">
                                <ThemeProvider theme={theme}>
                                    <Container style={{direction: 'ltr'}} >            
                                        <IntersectionObserver >
                                            {result[2].map((result) => 
                                                <ProgressBar percents={result[0]} color={colors[result[0]]} />
                                            )}
                                        </IntersectionObserver>
                                    </Container>
                                </ThemeProvider>
                                </div>

                                {result[2].map((result) => 
                                <div class="progress" style={{height: '20px',marginBottom: '10px'}}>
                                    <div
                                        class="progress-bar"
                                        role="progressbar"
                                        style={{width: result[0]+'%',backgroundColor:colors[result[0]]}}
                                        aria-valuenow={result[0]}
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                    >
                                        {result[0]}%
                                    </div>
                                </div>
                                )}
                            </div>
                            
                            :null}
                            <div className="col-12 col-sm-4 col-md-6 text-right">
                                {result[2].map((result) => 
                                <div className="lang_name dropdown">
                                <a href="javascript:void(0);" data-toggle="dropdown">{result[10]} <i className="fas fa-chevron-down" />
                                </a><div className="dropdown-menu nav_mega"><a href="javascript:void(0);" data-toggle="dropdown">
                                    </a><div className={flag?'dropdown_content_en':'dropdown_content'} style={{direction:direction}}>
                                        <a href="javascript:void(0);" data-toggle="dropdown">
                                        
                                        <h3>{flag?scoresEn[result[0]]:scores[result[0]]}</h3>
                                        {parse(result[2])}
                                        {result[11].length>0?
                                        <div>
                                        <span class="comment_title">{t('commentTitle')}</span>
                                        <br></br>
                                        <br></br>
                                        
                                        <span style={{fontSize:'14px',direction:direction}}>{parse(result[11])}</span>
                                        
                                        </div>
                                        :null}
                                        <br></br>
                                        <br></br>
                                        <h3>{result[3]}</h3>
                                    </a>
                                        <div style={{textAlign:position}}>
                                            <a target="_blank" href={result[8]} >
                                                {result[5].length > 1 ?<img src={`https://admin.lovush.com/uploads/`+result[5]} alt="&nbsp;" height="70px" width="70px"/>
                                                :null}<span>{result[4]}</span>
                                            </a>
                                            <br></br>
                                            <br></br>
                                            <a target="_blank" href={result[9]} >
                                            {result[7].length > 1 ?<img src={`https://admin.lovush.com/uploads/`+result[7]} alt="&nbsp;" height="70px" width="70px"/>
                                               :null} <span>{result[6]}</span>
                                            </a>
                                        </div>
                                  
                                    </div>
                                </div>
                                </div>
                                )}
                                
                                {/* <div className="lang_name dropdown">
                                <a href="javascript:void(0);" data-toggle="dropdown">אמונות חוסמות <i className="fas fa-chevron-down" />
                                </a><div className="dropdown-menu nav_mega"><a href="javascript:void(0);" data-toggle="dropdown">
                                    </a><div className="dropdown_content"><a href="javascript:void(0);" data-toggle="dropdown">
                                        
                                        <h3>{scores[result[2][1][0]]}</h3>
                                        {parse(result[2][1][2])}
                                        {result[3][4].length>0?
                                        <div>
                                        <span class="comment_title">ההערות המתייחסות למצבכם האישי בנושא זה הן:</span>
                                        <br></br>
                                        <br></br>
                                        
                                        <span style={{fontSize:'14px',direction:'rtl'}}>{parse(result[3][4])}</span>
                                        
                                        </div>
                                        :null}
                                        <br></br>
                                        <br></br>
                                        <h3>{result[2][1][3]}</h3>
                                    </a>
                                        <div style={{textAlign:'right'}}>
                                            <a target="_blank" href={result[2][1][8]} >
                                            {result[2][1][5].length > 1 ?<img src={`https://admin.lovush.com/uploads/`+result[2][1][5]} alt="&nbsp;" height="70px" width="70px"/>
                                               :null} <span>{result[2][1][4]}</span>
                                            </a>
                                            <br></br>
                                            <br></br>
                                            <a target="_blank" href={result[2][1][9]} >
                                                {result[2][1][7].length > 1 ?<img src={`https://admin.lovush.com/uploads/`+result[2][1][7]} alt="&nbsp;" height="70px" width="70px"/>
                                                :null}<span>{result[2][1][6]}</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                </div>

                                <div className="lang_name dropdown">
                                <a href="javascript:void(0);" data-toggle="dropdown">הגוף הפיסי <i className="fas fa-chevron-down" />
                                </a><div className="dropdown-menu nav_mega"><a href="javascript:void(0);" data-toggle="dropdown">
                                    </a><div className="dropdown_content"><a href="javascript:void(0);" data-toggle="dropdown">
                                        
                                        <h3>{scores[result[2][2][0]]}</h3>
                                        {parse(result[2][2][2])}
                                        {result[3][5].length>0?
                                        <div>
                                        <span class="comment_title">ההערות המתייחסות למצבכם האישי בנושא זה הן:</span>
                                        <br></br>
                                        <br></br>
                                        
                                        <span style={{fontSize:'14px',direction:'rtl'}}>{parse(result[3][5])}</span>
                                        
                                        </div>
                                        :null}
                                        <br></br>
                                        <br></br>
                                        <h3>{result[2][2][3]}</h3>
                                    </a>
                                        <div style={{textAlign:'right'}}>
                                            <a target="_blank" href={result[2][2][8]} >
                                            {result[2][2][5].length > 1 ?<img src={`https://admin.lovush.com/uploads/`+result[2][2][5]} alt="&nbsp;" height="70px" width="70px"/>
                                              :null}  <span>{result[2][2][4]}</span>
                                            </a>
                                            <br></br>
                                            <br></br>
                                            <a target="_blank" href={result[2][2][9]} >
                                                {result[2][2][7].length > 1 ?<img src={`https://admin.lovush.com/uploads/`+result[2][2][7]} alt="&nbsp;" height="70px" width="70px"/>
                                               :null} <span>{result[2][2][6]}</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                </div>
                                <div className="lang_name dropdown">
                                <a href="javascript:void(0);" data-toggle="dropdown">אהבת העצמי <i className="fas fa-chevron-down" />
                                </a><div className="dropdown-menu nav_mega"><a href="javascript:void(0);" data-toggle="dropdown">
                                    </a><div className="dropdown_content"><a href="javascript:void(0);" data-toggle="dropdown">
                                        
                                        <h3>{scores[result[2][3][0]]}</h3>
                                        {parse(result[2][3][2])}
                                        {result[3][6].length>0?
                                        <div>
                                        <span class="comment_title">ההערות המתייחסות למצבכם האישי בנושא זה הן:</span>
                                        <br></br>
                                        <br></br>
                                        
                                        <span style={{fontSize:'14px',direction:'rtl'}}>{parse(result[3][6])}</span>
                                        
                                        </div>
                                        :null}
                                        <br></br>
                                        <br></br>

                                        <h3>{result[2][3][3]}</h3>
                                    </a>
                                        <div style={{textAlign:'right'}}>
                                            <a target="_blank" href={result[2][3][8]} >
                                            {result[2][3][5].length > 1 ?<img src={`https://admin.lovush.com/uploads/`+result[2][3][5]} alt="&nbsp;" height="70px" width="70px"/>
                                              :null}  <span>{result[2][3][4]}</span>
                                            </a>
                                            <br></br>
                                            <br></br>
                                            <a target="_blank" href={result[2][3][9]} >
                                                {result[2][3][7].length > 1 ?<img src={`https://admin.lovush.com/uploads/`+result[2][3][7]} alt="&nbsp;" height="70px" width="70px"/>
                                             :null}   <span>{result[2][3][6]}</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                </div>
                                <div className="lang_name dropdown">
                                <a href="javascript:void(0);" data-toggle="dropdown">התפשרות <i className="fas fa-chevron-down" />
                                </a><div className="dropdown-menu nav_mega"><a href="javascript:void(0);" data-toggle="dropdown">
                                    </a><div className="dropdown_content"><a href="javascript:void(0);" data-toggle="dropdown">
                                        
                                        <h3>{scores[result[2][4][0]]}</h3>
                                        {parse(result[2][4][2])}
                                        {result[3][7].length>0?
                                        <div>
                                        <span class="comment_title">ההערות המתייחסות למצבכם האישי בנושא זה הן:</span>
                                        <br></br>
                                        <br></br>
                                        
                                        <span style={{fontSize:'14px',direction:'rtl'}}>{parse(result[3][7])}</span>
                                        
                                        </div>
                                        :null}
                                        <br></br>
                                        <br></br>
                                        
                                        <h3>{result[2][4][3]}</h3>
                                    </a>
                                        <div style={{textAlign:'right'}}>
                                            <a target="_blank" href={result[2][4][8]} >
                                            {result[2][4][5].length > 1 ?<img src={`https://admin.lovush.com/uploads/`+result[2][4][5]} alt="&nbsp;" height="70px" width="70px"/>
                                                :null}<span>{result[2][4][4]}</span>
                                            </a>
                                            <br></br>
                                            <br></br>
                                            <a target="_blank" href={result[2][4][9]} >
                                                {result[2][4][7].length > 1 ?<img src={`https://admin.lovush.com/uploads/`+result[2][4][7]} alt="&nbsp;" height="70px" width="70px"/>
                                               :null} <span>{result[2][4][6]}</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                </div>
                                <div className="lang_name dropdown">
                                <a href="javascript:void(0);" data-toggle="dropdown">אנוכיות בריאה <i className="fas fa-chevron-down" />
                                </a><div className="dropdown-menu nav_mega"><a href="javascript:void(0);" data-toggle="dropdown">
                                    </a><div className="dropdown_content"><a href="javascript:void(0);" data-toggle="dropdown">
                                        
                                        <h3>{scores[result[2][5][0]]}</h3>
                                        {parse(result[2][5][2])}
                                        {result[3][8].length>0?
                                        <div>
                                        <span class="comment_title">ההערות המתייחסות למצבכם האישי בנושא זה הן:</span>
                                        <br></br>
                                        <br></br>
                                        
                                        <span style={{fontSize:'14px',direction:'rtl'}}>{parse(result[3][8])}</span>
                                        
                                        </div>
                                        :null}
                                        <br></br>
                                        <br></br>

                                        <h3>{result[2][5][3]}</h3>
                                    </a>
                                        <div style={{textAlign:'right'}}>
                                            <a target="_blank" href={result[2][5][8]} >
                                            {result[2][5][5].length > 1 ?<img src={`https://admin.lovush.com/uploads/`+result[2][5][5]} alt="&nbsp;" height="70px" width="70px"/>
                                               :null} <span>{result[2][5][4]}</span>
                                            </a>
                                            <br></br>
                                            <br></br>
                                            <a target="_blank" href={result[2][5][9]} >
                                                {result[2][5][7].length > 1 ?<img src={`https://admin.lovush.com/uploads/`+result[2][5][7]} alt="&nbsp;" height="70px" width="70px"/>
                                               :null} <span>{result[2][5][6]}</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                </div>
                                <div className="lang_name dropdown">
                                <a href="javascript:void(0);" data-toggle="dropdown">רגע ההווה <i className="fas fa-chevron-down" />
                                </a><div className="dropdown-menu nav_mega"><a href="javascript:void(0);" data-toggle="dropdown">
                                    </a><div className="dropdown_content"><a href="javascript:void(0);" data-toggle="dropdown">
                                        
                                        <h3>{scores[result[2][6][0]]}</h3>
                                        {parse(result[2][6][2])}
                                        {result[3][9].length>0?
                                        <div>
                                        <span class="comment_title">ההערות המתייחסות למצבכם האישי בנושא זה הן:</span>
                                        <br></br>
                                        <br></br>
                                        
                                        <span style={{fontSize:'14px',direction:'rtl'}}>{parse(result[3][9])}</span>
                                        
                                        </div>
                                        :null}
                                        <br></br>
                                        <br></br>
                                        
                                        <h3>{result[2][6][3]}</h3>
                                    </a>
                                        <div style={{textAlign:'right'}}>
                                            <a target="_blank" href={result[2][6][8]} >
                                            {result[2][6][5].length > 1 ?<img src={`https://admin.lovush.com/uploads/`+result[2][6][5]} alt="&nbsp;" height="70px" width="70px"/>
                                               :null} <span>{result[2][6][4]}</span>
                                            </a>
                                            <br></br>
                                            <br></br>
                                            <a target="_blank" href={result[2][6][9]} >
                                                {result[2][6][7].length > 1 ?<img src={`https://admin.lovush.com/uploads/`+result[2][6][7]} alt="&nbsp;" height="70px" width="70px"/>
                                                :null}<span>{result[2][6][6]}</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                </div>
                                <div className="lang_name dropdown">
                                <a href="javascript:void(0);" data-toggle="dropdown">קלות החיים <i className="fas fa-chevron-down" />
                                </a><div className="dropdown-menu nav_mega"><a href="javascript:void(0);" data-toggle="dropdown">
                                    </a><div className="dropdown_content"><a href="javascript:void(0);" data-toggle="dropdown">
                                        
                                        <h3>{scores[result[2][7][0]]}</h3>
                                        {parse(result[2][7][2])}
                                        {result[3][10].length>0?
                                        <div>
                                        <span class="comment_title">ההערות המתייחסות למצבכם האישי בנושא זה הן:</span>
                                        <br></br>
                                        <br></br>
                                        
                                        <span style={{fontSize:'14px',direction:'rtl'}}>{parse(result[3][10])}</span>
                                        
                                        </div>
                                        :null}
                                        <br></br>
                                        <br></br>
                                        
                                        <h3>{result[2][7][3]}</h3>
                                    </a>
                                        <div style={{textAlign:'right'}}>
                                            <a target="_blank" href={result[2][7][8]} >
                                            {result[2][7][5].length > 1 ?<img src={`https://admin.lovush.com/uploads/`+result[2][7][5]} alt="&nbsp;" height="70px" width="70px"/>
                                                :null}<span>{result[2][7][4]}</span>
                                            </a>
                                            <br></br>
                                            <br></br>
                                            <a target="_blank" href={result[2][7][9]} >
                                                {result[2][7][7].length > 1 ?<img src={`https://admin.lovush.com/uploads/`+result[2][7][7]} alt="&nbsp;" height="70px" width="70px"/>
                                               :null} <span>{result[2][7][6]}</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                </div>
                                <div className="lang_name dropdown">
                                <a href="javascript:void(0);" data-toggle="dropdown">אמון <i className="fas fa-chevron-down" />
                                </a><div className="dropdown-menu nav_mega"><a href="javascript:void(0);" data-toggle="dropdown">
                                    </a><div className="dropdown_content"><a href="javascript:void(0);" data-toggle="dropdown">
                                        
                                        <h3>{scores[result[2][8][0]]}</h3>
                                        {parse(result[2][8][2])}
                                        {result[3][11].length>0?
                                        <div>
                                        <span class="comment_title">ההערות המתייחסות למצבכם האישי בנושא זה הן:</span>
                                        <br></br>
                                        <br></br>
                                        
                                        <span style={{fontSize:'14px',direction:'rtl'}}>{parse(result[3][11])}</span>
                                        
                                        </div>
                                        :null}
                                        <br></br>
                                        <br></br>
                                        
                                        <h3>{result[2][8][3]}</h3>
                                    </a>
                                        <div style={{textAlign:'right'}}>
                                            <a target="_blank" href={result[2][8][8]} >
                                            {result[2][8][5].length > 1 ?<img src={`https://admin.lovush.com/uploads/`+result[2][8][5]} alt="&nbsp;" height="70px" width="70px"/>
                                                :null}<span>{result[2][8][4]}</span>
                                            </a>
                                            <br></br>
                                            <br></br>
                                            <a target="_blank" href={result[2][8][9]} >
                                                {result[2][8][7].length > 1 ?<img src={`https://admin.lovush.com/uploads/`+result[2][8][7]} alt="&nbsp;" height="70px" width="70px"/>
                                                :null}<span>{result[2][8][6]}</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                </div>
                                <div className="lang_name dropdown">
                                <a href="javascript:void(0);" data-toggle="dropdown">שיחרור <i className="fas fa-chevron-down" />
                                </a><div className="dropdown-menu nav_mega"><a href="javascript:void(0);" data-toggle="dropdown">
                                    </a><div className="dropdown_content"><a href="javascript:void(0);" data-toggle="dropdown">
                                        
                                        <h3>{scores[result[2][9][0]]}</h3>
                                        {parse(result[2][9][2])}
                                        {result[3][12].length>0?
                                        <div>
                                        <span class="comment_title">ההערות המתייחסות למצבכם האישי בנושא זה הן:</span>
                                        <br></br>
                                        <br></br>
                                        
                                        <span style={{fontSize:'14px',direction:'rtl'}}>{parse(result[3][12])}</span>
                                        
                                        </div>
                                        :null}
                                        <br></br>
                                        <br></br>
                                        
                                        <h3>{result[2][9][3]}</h3>
                                    </a>
                                        <div style={{textAlign:'right'}}>
                                            <a target="_blank" href={result[2][9][8]} >
                                            {result[2][9][5].length > 1 ?<img src={`https://admin.lovush.com/uploads/`+result[2][9][5]} alt="&nbsp;" height="70px" width="70px"/>
                                                :null}<span>{result[2][9][4]}</span>
                                            </a>
                                            <br></br>
                                            <br></br>
                                            <a target="_blank" href={result[2][9][9]} >
                                                {result[2][9][7].length > 1 ?<img src={`https://admin.lovush.com/uploads/`+result[2][9][7]} alt="&nbsp;" height="70px" width="70px"/>
                                                :null}<span>{result[2][9][6]}</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                </div>
                                <div className="lang_name dropdown">
                                <a href="javascript:void(0);" data-toggle="dropdown">בחירה ומיקוד <i className="fas fa-chevron-down" />
                                </a><div className="dropdown-menu nav_mega"><a href="javascript:void(0);" data-toggle="dropdown">
                                    </a><div className="dropdown_content"><a href="javascript:void(0);" data-toggle="dropdown">
                                        
                                        <h3>{scores[result[2][10][0]]}</h3>
                                        {parse(result[2][10][2])}
                                        {result[3][13].length>0?
                                        <div>
                                        <span class="comment_title">ההערות המתייחסות למצבכם האישי בנושא זה הן:</span>
                                        <br></br>
                                        <br></br>
                                        
                                        <span style={{fontSize:'14px',direction:'rtl'}}>{parse(result[3][13])}</span>
                                        
                                        </div>
                                        :null}
                                        <br></br>
                                        <br></br>
                                        
                                        <h3>{result[2][10][3]}</h3>
                                    </a>
                                        <div style={{textAlign:'right'}}>
                                            <a target="_blank" href={result[2][10][8]} >
                                            {result[2][10][5].length > 1 ?<img src={`https://admin.lovush.com/uploads/`+result[2][10][5]} alt="&nbsp;" height="70px" width="70px"/>
                                                :null}<span>{result[2][10][4]}</span>
                                            </a>
                                            <br></br>
                                            <br></br>
                                            <a target="_blank" href={result[2][10][9]} >
                                                {result[2][10][7].length > 1 ?<img src={`https://admin.lovush.com/uploads/`+result[2][10][7]} alt="&nbsp;" height="70px" width="70px"/>
                                                :null}<span>{result[2][10][6]}</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                </div>
                                <div className="lang_name dropdown">
                                <a href="javascript:void(0);" data-toggle="dropdown">הקול הפנימי <i className="fas fa-chevron-down" />
                                </a><div className="dropdown-menu nav_mega"><a href="javascript:void(0);" data-toggle="dropdown">
                                    </a><div className="dropdown_content"><a href="javascript:void(0);" data-toggle="dropdown">
                                        
                                        <h3>{scores[result[2][11][0]]}</h3>
                                        {parse(result[2][11][2])}
                                        {result[3][14].length>0?
                                        <div>
                                        <span class="comment_title">ההערות המתייחסות למצבכם האישי בנושא זה הן:</span>
                                        <br></br>
                                        <br></br>
                                        
                                        <span style={{fontSize:'14px',direction:'rtl'}}>{parse(result[3][14])}</span>
                                        
                                        </div>
                                        :null}
                                        <br></br>
                                        <br></br>
                                        
                                        <h3>{result[2][11][3]}</h3>
                                    </a>
                                        <div style={{textAlign:'right'}}>
                                            <a target="_blank" href={result[2][11][8]} >
                                            {result[2][11][5].length > 1 ?<img src={`https://admin.lovush.com/uploads/`+result[2][11][5]} alt="&nbsp;" height="70px" width="70px"/>
                                                :null}<span>{result[2][11][4]}</span>
                                            </a>
                                            <br></br>
                                            <br></br>
                                            <a target="_blank" href={result[2][11][9]} >
                                                {result[2][11][7].length > 1 ?<img src={`https://admin.lovush.com/uploads/`+result[2][11][7]} alt="&nbsp;" height="70px" width="70px"/>
                                                :null}<span>{result[2][11][6]}</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                </div>
                                <div className="lang_name dropdown">
                                <a href="javascript:void(0);" data-toggle="dropdown">יושר <i className="fas fa-chevron-down" />
                                </a><div className="dropdown-menu nav_mega"><a href="javascript:void(0);" data-toggle="dropdown">
                                    </a><div className="dropdown_content"><a href="javascript:void(0);" data-toggle="dropdown">
                                        
                                        <h3>{scores[result[2][12][0]]}</h3>
                                        {parse(result[2][11][2])}
                                        {result[3][15].length>0?
                                        <div>
                                        <span class="comment_title">ההערות המתייחסות למצבכם האישי בנושא זה הן:</span>
                                        <br></br>
                                        <br></br>
                                        
                                        <span style={{fontSize:'14px',direction:'rtl'}}>{parse(result[3][15])}</span>
                                        
                                        </div>
                                        :null}
                                        <br></br>
                                        <br></br>
                                        
                                        <h3>{result[2][12][3]}</h3>
                                    </a>
                                        <div style={{textAlign:'right'}}>
                                            <a target="_blank" href={result[2][12][8]} >
                                            {result[2][12][5].length > 1 ?<img src={`https://admin.lovush.com/uploads/`+result[2][12][5]} alt="&nbsp;" height="70px" width="70px"/>
                                                :null}<span>{result[2][12][4]}</span>
                                            </a>
                                            <br></br>
                                            <br></br>
                                            <a target="_blank" href={result[2][12][9]} >
                                                {result[2][12][7].length > 1 ?<img src={`https://admin.lovush.com/uploads/`+result[2][12][7]} alt="&nbsp;" height="70px" width="70px"/>
                                                :null}<span>{result[2][12][6]}</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                </div>
                                <div className="lang_name dropdown">
                                <a href="javascript:void(0);" data-toggle="dropdown">תסמונת הקורבן <i className="fas fa-chevron-down" />
                                </a><div className="dropdown-menu nav_mega"><a href="javascript:void(0);" data-toggle="dropdown">
                                    </a><div className="dropdown_content"><a href="javascript:void(0);" data-toggle="dropdown">
                                        
                                        <h3>{scores[result[2][13][0]]}</h3>
                                        {parse(result[2][13][2])}
                                        {result[3][16].length>0?
                                        <div>
                                        <span class="comment_title">ההערות המתייחסות למצבכם האישי בנושא זה הן:</span>
                                        <br></br>
                                        <br></br>
                                        
                                        <span style={{fontSize:'14px',direction:'rtl'}}>{parse(result[3][16])}</span>
                                        
                                        </div>
                                        :null}
                                        <br></br>
                                        <br></br>
                                        
                                        <h3>{result[2][13][3]}</h3>
                                    </a>
                                        <div style={{textAlign:'right'}}>
                                            <a target="_blank" href={result[2][13][8]} >
                                            {result[2][13][5].length > 1 ?<img src={`https://admin.lovush.com/uploads/`+result[2][13][5]} alt="&nbsp;" height="70px" width="70px"/>
                                                :null}<span>{result[2][13][4]}</span>
                                            </a>
                                            <br></br>
                                            <br></br>
                                            <a target="_blank" href={result[2][13][9]} >
                                                {result[2][13][7].length > 1 ?<img src={`https://admin.lovush.com/uploads/`+result[2][13][7]} alt="&nbsp;" height="70px" width="70px"/>
                                                :null}<span>{result[2][13][6]}</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                </div>
                                <div className="lang_name dropdown">
                                <a href="javascript:void(0);" data-toggle="dropdown">כישורים חברתיים <i className="fas fa-chevron-down" />
                                </a><div className="dropdown-menu nav_mega"><a href="javascript:void(0);" data-toggle="dropdown">
                                    </a><div className="dropdown_content"><a href="javascript:void(0);" data-toggle="dropdown">
                                        
                                        <h3>{scores[result[2][14][0]]}</h3>
                                        {parse(result[2][14][2])}
                                        {result[3][17].length>0?
                                        <div>
                                        <span class="comment_title">ההערות המתייחסות למצבכם האישי בנושא זה הן:</span>
                                        <br></br>
                                        <br></br>
                                        
                                        <span style={{fontSize:'14px',direction:'rtl'}}>{parse(result[3][17])}</span>
                                        
                                        </div>
                                        :null}
                                        <br></br>
                                        <br></br>
                                        
                                        <h3>{result[2][14][3]}</h3>
                                    </a>
                                        <div style={{textAlign:'right'}}>
                                            <a target="_blank" href={result[2][14][8]} >
                                            {result[2][14][5].length > 1 ?<img src={`https://admin.lovush.com/uploads/`+result[2][14][5]} alt="&nbsp;" height="70px" width="70px"/>
                                                :null}<span>{result[2][14][4]}</span>
                                            </a>
                                            <br></br>
                                            <br></br>
                                            <a target="_blank" href={result[2][14][9]} >
                                                {result[2][14][7].length > 1 ?<img src={`https://admin.lovush.com/uploads/`+result[2][14][7]} alt="&nbsp;" height="70px" width="70px"/>
                                                :null}<span>{result[2][14][6]}</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                </div>
                                <div className="lang_name dropdown">
                                <a href="javascript:void(0);" data-toggle="dropdown">לחגוג את החיים <i className="fas fa-chevron-down" />
                                </a><div className="dropdown-menu nav_mega"><a href="javascript:void(0);" data-toggle="dropdown">
                                    </a><div className="dropdown_content"><a href="javascript:void(0);" data-toggle="dropdown">
                                        
                                        <h3>{scores[result[2][15][0]]}</h3>
                                        {parse(result[2][15][2])}
                                        {result[3][18].length>0?
                                        <div>
                                        <span class="comment_title">ההערות המתייחסות למצבכם האישי בנושא זה הן:</span>
                                        <br></br>
                                        <br></br>
                                        
                                        <span style={{fontSize:'14px',direction:'rtl'}}>{parse(result[3][18])}</span>
                                        
                                        </div>
                                        :null}
                                        <br></br>
                                        <br></br>
                                        
                                        <h3>{result[2][15][3]}</h3>
                                    </a>
                                        <div style={{textAlign:'right'}}>
                                            <a target="_blank" href={result[2][15][8]} >
                                            {result[2][15][5].length > 1 ?<img src={`https://admin.lovush.com/uploads/`+result[2][15][5]} alt="&nbsp;" height="70px" width="70px"/>
                                                :null}<span>{result[2][15][4]}</span>
                                            </a>
                                            <br></br>
                                            <br></br>
                                            <a target="_blank" href={result[2][15][9]} >
                                                {result[2][15][7].length > 1 ?<img src={`https://admin.lovush.com/uploads/`+result[2][15][7]} alt="&nbsp;" height="70px" width="70px"/>
                                                :null}<span>{result[2][15][6]}</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                </div> */}
                               
                            </div>
                            <div className="col-12 col-sm-12">
                                <div className="share_icons text-left mb-2">
                                <span> [&nbsp;
                                    <EmailShareButton url={shareUrl}>
                                        <a href><i className="fas fa-envelope" /></a>
                                    </EmailShareButton>&emsp; 
                                    <FacebookShareButton url={shareUrl}>
                                        <a href><i className="fab fa-facebook" /></a>
                                    </FacebookShareButton>&emsp;
                                    {/* <a href><i className="fab fa-instagram" /></a>&emsp; */}
                                    <WhatsappShareButton url={shareUrl}>
                                        <a href><i className="fab fa-whatsapp-square" /></a>
                                    </WhatsappShareButton>&nbsp;
                                    ] </span><span>{t('shareResult')}</span> 
                                    
                                    <button  className="smallbtns custom_btn text-center mt-0 mb-2" style={{border:'0',padding:'0',cursor:'pointer',background:'none'}} onClick={print}><Link style={{padding:"4px 18px"}}>{t('print')}</Link></button>
                                    <button  className="smallbtns custom_btn text-center mt-0 mb-2" style={{border:'0',padding:'0',cursor:'pointer',background:'none'}}><Link to="/logout" style={{padding:"4px 18px"}}>{t('goOut')}</Link></button>
                                   
                                </div>
                            </div>
                            <div className="col-12 col-sm-5">
                                {/* <div className="smallbtns custom_btn text-center mt-0 mb-2">
                                <button  style={{border:'0',padding:'0',cursor:'pointer',background:'none'}} onClick={print}><Link>Print</Link></button>
                                </div> */}
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
)}
}

export default Result
