import React,{Fragment, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import Footer from './Footer'
import { useHistory } from 'react-router';
import jwt_decode from 'jwt-decode'
import { client_answers, texts} from './UserFunctions'
import parse from 'html-react-parser'
import { useTranslation } from 'react-i18next';


function Question() {

    const history = useHistory();
    const [text,setText] = useState('')
    const [direction, setDirection] = useState('')
    const [position, setPosition] = useState('')
    const { t } = useTranslation();


    useEffect(() => {
        if(localStorage.getItem("usertoken")==null || localStorage.getItem("usertoken")==''){
            history.push(`/home`)
          }
    });

    
    useEffect(() => {
        let mounted = true;

        const token = localStorage.usertoken
        const decoded = jwt_decode(token)
            
      
        
        const fetchData = () => {
            

            const idToPass = {
                userId : decoded.id,
            }

            texts()
            .then(async userData => {

                if(userData)
                {
                    if (localStorage.getItem('language') === 'he')
                    {
                        await setText(userData.data[3].body_he)
                        setDirection('rtl')
                        setPosition('right')
                    }
                    else
                    {
                        await setText(userData.data[3].body_en)
                        setDirection('ltr')
                        setPosition('left')
                    }
                    
                }

            })
            .catch((error) => { 
                console.log("Error!")
            });

            client_answers(idToPass)
            .then(userData => {

                var temp = userData.data.number_q

                if(temp!=79)
                {
                    history.push(`/question`)
                }
               
                

            })
            .catch((error) => { 
                console.log("Error! Something went wrong")
            });

            

            
        }

        fetchData();
        return () => {
            mounted = false;
        };

    },[text]);



  

    return (
        <div className="questions_bg before_img Finished">
            <div className="wrapper">
                <div className="container">
                    <div className="row">
                    <div className="col-12 text-center">
                        <div className="logo wow animate__fadeInLeft animate__animated">
                        <a href><img src="resources/images/logo.png" /></a>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="welcomeback">
                    <form>
                    <div className="container">
                    <div className="row">
                        <div className="col-12 text-center">
                        <div className="ipade_screen text_desc mb-0">
                            <div className="ipade_inner wow animate__fadeInLeft animate__animated">
                            <div style={{textAlign:position}}>
                                
                                <p>
                                {text.length>0?
                                    parse(text)
                                :null}
                                </p>

                            </div>
                            </div>
                        </div>
                        <div className="smallbtns custom_btn" style={{direction:direction}}>
                            <Link to="/result">{t('continue')}</Link>
                            <Link to="/logout">{t('goOut')}</Link>
                        </div>
                        </div>
                    </div>
                    </div>
                    </form>

                </div>
            </div>
        <Footer />
        </div>
    )
}

export default Question
