import React,{useEffect, useState} from 'react'
import { texts } from './UserFunctions'
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import parse from 'html-react-parser'

import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import i18n from "./../i18n";

const useStyles = makeStyles((theme) => ({
  button: {
    display: 'block',
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 160,
  },
}));


const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });
  
  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, position, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6" style={{textAlign:position}}>{children}</Typography>
      </MuiDialogTitle>
    );
  });
  
  const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiDialogContent);
  
  const DialogActions = withStyles((theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(1),
    },
  }))(MuiDialogActions);

function Footer() {

    const [about,setAbout]=useState('')
    const [how,setHow]=useState('')
    const [open, setOpen] = React.useState(false);
    const [openHow, setHowModal] = React.useState(false);
    const { t } = useTranslation();

    const classes = useStyles();
    const [language, setLanguage] = React.useState('');
    const [openLang, setOpenLang] = React.useState(false);
    const [direction, setDirection] = useState('')
    const [position, setPosition] = useState('')

    const handleChange = (event) => {
        setLanguage(event.target.value);
        i18n.changeLanguage(event.target.value)
        localStorage.setItem('language',event.target.value)
        if (event.target.value === 'he') {
        document.body.style.direction = "rtl";
        } else {
        document.body.style.direction = "ltr";
        }
        window.location.reload(false); 

    };

    const handleCloseLang = () => {
        setOpenLang(false);
    };

    const handleOpenLang = () => {
        setOpenLang(true);
    };


    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    

    const handleHowOpen = () => {
        setHowModal(true);
    };
    const handleHowClose = () => {
        setHowModal(false);
    };

    useEffect(() => {


        texts()
        .then(async userData => {

            if(userData)
            {
                if (localStorage.getItem('language') === 'he')
                {
                    await setAbout(userData.data[0].body_he)
                    await setHow(userData.data[1].body_he)
                    setDirection('rtl')
                    setPosition('right')
                }
                else
                {
                    await setAbout(userData.data[0].body_en)
                    await setHow(userData.data[1].body_en)
                    setDirection('ltr')
                    setPosition('left')
                }
            }

        })
        .catch((error) => { 
            console.log("Error!")
        });
    },[how])

    


    return (
        <div>
            <footer>
                <div className="container">
                <div className="row">
                {/* {window.location.pathname == '/login' ?
                <div className="col-12">
                        <FormControl className={classes.formControl}>
                            <InputLabel style={{color:'white'}} id="demo-controlled-open-select-label">{t('language')}</InputLabel>
                            <Select
                            labelId="demo-controlled-open-select-label"
                            id="demo-controlled-open-select"
                            open={openLang}
                            onClose={handleCloseLang}
                            onOpen={handleOpenLang}
                            value={language}
                            onChange={handleChange}
                            >
                                <MenuItem value={'he'}>עִברִית</MenuItem>
                                <MenuItem value={'en'}>English</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    : 
                    null
                    } */}
                    <div className="col-12 text-center">
                        <div className="footer_links">
                        
                            <Button  onClick={handleClickOpen}>{t('aboutTheTest')}</Button>
                            <Button onClick={handleHowOpen} className="first_child">{t('howToAnswer')}</Button>
                            
                        </div>
                        <p style={{direction:'rtl'}} className="mb-0">{t('rights')}</p>
                    </div>
                    
                </div>
                </div>
            </footer>
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                <DialogTitle position={position} id="customized-dialog-title" onClose={handleClose}  style={{direction:direction}}>
                {t('aboutTheTest')}
                </DialogTitle>
                <DialogContent dividers>
                <Typography gutterBottom style={{direction:direction,textAlign:position}}>
                    {parse(about)}
                </Typography>
                </DialogContent>
                <DialogActions>
                <Button autoFocus onClick={handleClose} color="primary">
                    Close
                </Button>
                </DialogActions>
            </Dialog>
            <Dialog onClose={handleHowClose} aria-labelledby="customized-dialog-title" open={openHow}>
                <DialogTitle  position={position} id="customized-dialog-title" onClose={handleHowClose}  style={{direction:direction}}>
                {t('howToAnswer')}
                </DialogTitle>
                <DialogContent dividers>
                <Typography gutterBottom style={{direction:direction,textAlign:position}}>
                    {parse(how)}
                </Typography>
                </DialogContent>
                <DialogActions>
                <Button autoFocus onClick={handleHowClose} color="primary">
                    Close
                </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default Footer
