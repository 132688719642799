import React, { useContext } from "react";

import styled from "styled-components";

import { MotionBox } from "./MotionBox";
import { IntersectionContext } from "./IntersectionObserver";

import { Box } from "./Box";
import { Typography } from "./Typography";
import { Flex } from "./Flex";

const Bar = styled(Box)`
  overflow: hidden;
`;
Bar.defaultProps = {
  position: "relative",
  borderRadius: 2
};

const BarFilling = styled(MotionBox)``;
BarFilling.defaultProps = {
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%"
};

const BarCaption = styled(Box)``;
BarCaption.defaultProps = {
  position: "relative",
  textAlign: "right",
  // lineHeight: 0,
  fontWeight: 0,
  pr: 2
};

const BarPercents = styled(Typography)``;
BarPercents.defaultProps = {
  color: "text500",
  fontWeight: 2,
  pl: 1
};

export const ProgressBar = ({
  percents,
  color,
  caption,
  duration = 3,
  delay = 0.5,
  easing = "easeInOut", // [number, number, number, number] | "linear" | "easeIn" | "easeOut" | "easeInOut" | "circIn" | "circOut" | "circInOut" | "backIn" | "backOut" | "backInOut" | "anticipate" | EasingFunction;
  barWidth = 441,
  barHeight = 15,
  progressColor = "brand",
  baseColor = "bg100"
}) => {
  const { inView } = useContext(IntersectionContext);

  const percentsOffset = (percents - 100) * (barWidth / 100);

  const transition = {
    duration: duration,
    delay: delay,
    ease: easing
  };

  const variants = {
    enter: {
      // opacity: 0,
      x: -barWidth
    },
    animate: {
      // opacity: 1,
      x: [-barWidth, percentsOffset],
      transition
    }
  };

  return (
    <Flex my={1}>
      <Bar width={barWidth} height={barHeight} bg={baseColor} style={{borderRadius: '3px'}}>
        <BarFilling
          variants={variants}
          initial="enter"
          animate={inView ? "animate" : "enter"}
          exit="enter"
          bg={progressColor}
          style={{backgroundColor: color}}
        />

        {caption && (
          <BarCaption
            fontSize={barHeight >= 20 ? 2 : "8px"}
            lineHeight={`${barHeight}px`}
          >
            {caption}
          </BarCaption>
        )}
      </Bar>
      <BarPercents>{percents}%</BarPercents>
    </Flex>
  );
};
