import React,{useEffect} from 'react'
import {Link} from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import i18n from "./../i18n";
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));


function Landing() {
  const { t } = useTranslation();
  const classes = useStyles();
  const [language, setLanguage] = React.useState('');
  const [open, setOpen] = React.useState(false);

  const handleChange = (event) => {

 
      setLanguage('he');
      i18n.changeLanguage('he')
      localStorage.setItem('language','he')
  
      document.body.style.direction = "rtl";
    

  };

  useEffect(() => {
    
    if (localStorage.getItem('language') === 'he')
    {
      setLanguage('he');
      i18n.changeLanguage('he')
      document.body.style.direction = "rtl";

    }else{

      setLanguage('en');
      i18n.changeLanguage('en') 
      document.body.style.direction = "ltr";

    }

  }, [language])


  const handleChangeEn = (event) => {

 
    setLanguage('en');
    i18n.changeLanguage('en')
    localStorage.setItem('language','en')

    document.body.style.direction = "ltr";
  

};

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  
    return (
    <div className="Landing">
      <div className="wrapper">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="logo wow animate__fadeInLeft animate__animated">
                <a href><img src="resources/images/logo.png" /></a>
                <div style={{direction:'ltr'}}>
                  <br></br>
                    <Button variant="outlined" onClick={handleChangeEn} color="secondary" style={{width:'50px',color:'white'}}>
                      EN
                    </Button>
                    &emsp;
                    <Button variant="outlined" onClick={handleChange} color="secondary" style={{width:'50px',color:'white'}}>
                    עב 
                    </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="lovush_screen  d-block">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                <div className="homelaptop wow animate__fadeInLeft animate__animated">
                  <h2>LOVUSH</h2><br />
                  <h4>{t('areYouMaster')}</h4>
                </div>
                <div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
          {language == 'he'?
            <div className="start_btn">
              <Link to="/login">התחילו <i className="fas fa-long-arrow-alt-left pull-left" /></Link>
            </div>
          :
            <div className="start_btn eng_style" style={{left:'10%'}}>
              <Link to="/login">Start <i className="fas fa-long-arrow-alt-right pull-left" /></Link>
            </div>
          }
        
      </div>
    </div>
    )
}

export default Landing
