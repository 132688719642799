import React,{Fragment, useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import Footer from './Footer'
import { signup, texts } from './UserFunctions'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import jwt_decode from 'jwt-decode'

function Landing() {

    const history = useHistory();
    const [email, setEmail] = useState('');
    const [fullName, setFullName] = useState('');
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [text, setText] = useState('')
    const [direction, changeDirection] = useState(false)
    const [loading, setLoading] = useState(true)
    const { t } = useTranslation();


    useEffect(() => {
         
        if(localStorage.getItem("usertoken")!=null && localStorage.getItem("usertoken")!=''){
            history.push(`/welcome`)
        }

        if (localStorage.getItem('language') === 'he')
        {
            changeDirection(false)
        }
        else
        {
            changeDirection(true)
        }


        // texts()
        // .then(async userData => {

        //     if(userData)
        //     {
        //         if (localStorage.getItem('language') === 'he')
        //         {
        //             setText(userData.data[2].body_he)
        //             changeDirection(false)
        //         }
        //         else
        //         {
        //             setText(userData.data[2].body_en)
        //             changeDirection(true)
        //         }
        //         setLoading(false)
        //     }

        // })
        // .catch((error) => { 
        //     console.log("Error!")
        // });


    },[text]);

    const handleSubmit = async (event) => {
        event.preventDefault();

        const user = {
            email, password, name: fullName, phone
        }

        signup(user)
        .then(userData => {

            if(userData)
            {
                toast.info(t('signupSuccess'))
                setTimeout(() => {
                    history.push(`/login`)
                }, 2000)
            }
            else
            toast.error(t('accExist'))

        })
        .catch((error) => { 
        toast.error(t('accExist'))
        });

    }


    // if(loading)
    // {
    //     return(
    //         <div className="Loading">
    //         <div className="wrapper">
    //             <div className="container">
    //                 <div className="row">
    //                 <div className="col-12 text-center">
    //                     <div className="logo wow animate__fadeInLeft animate__animated">
    //                     <a href><img src="resources/images/logo.png" /></a>
    //                     </div>
    //                 </div>
    //                 </div>
    //             </div>
    //             <div className="welcomeback"  style={{paddingTop:'10%'}}>
    //                 <div className="container">
    //                 <div className="row ">
    //                     <div className="col-12 text-center">
    //                         <div className="loading_2 mt-4">
    //                             <div className="lds-ripple"><div /><div /></div>
    //                         </div>
    //                     </div>
    //                 </div>
    //                 </div>
    //             </div>
    //         </div>
    //     </div>
    //     )
    // }else{
    return (
    
    <div className="Login">
        <div className="wrapper">
            <div className="container">
            <div className="row">
                <div className="col-12 text-center">
                <div className="logo wow animate__fadeInLeft animate__animated">
                    <a href><img src="resources/images/logo.png" /></a>
                </div>
                </div>
            </div>
            </div>
            <div className="welcomeback">
            <div className="container">
                <div className="row ">
                <div className="col-12 text-center">
                    <div className="text_desc wow animate__fadeInLeft animate__animated">
                    <p>
                    {text.length>1?
                        text
                    :null
                    }
                    </p>
                        </div>
                    <br></br>
                    <form className="mb-5" onSubmit={handleSubmit}>
                        <div className="row justify-content-center">
                            <div className="form-group col-12 col-sm-10 col-md-6" style={{marginBottom:'18px',marginTop:'-30px'}}>
                            <div className={direction ? 'animated_input_en' : 'animated_input'}>
                                <input type="text" name="fullName" className="form-control"
                                onChange={e => setFullName(e.target.value)}
                                 />
                                <label>{t('fullName')}</label>
                            </div>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="form-group col-12 col-sm-10 col-md-6" style={{marginBottom:'18px'}}>
                            <div className={direction ? 'animated_input_en' : 'animated_input'}>
                                <input type="text" name="phone" className="form-control"
                                onChange={e => setPhone(e.target.value)}
                                 />
                                <label>{t('phone')}</label>
                            </div>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="form-group col-12 col-sm-10 col-md-6" style={{marginBottom:'18px'}}>
                            <div className={direction ? 'animated_input_en' : 'animated_input'}>
                                <input type="text" name="email" className="form-control"
                                onChange={e => setEmail(e.target.value)}
                                required />
                                <label>{t('email')} <span style={{color:'red'}}>*</span></label>
                            </div>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="form-group col-12 col-sm-10 col-md-6" style={{marginBottom:'18px'}}>
                            <div className={direction ? 'animated_input_en' : 'animated_input'}>
                                <input type="password" name="password" className="form-control" 
                                onChange={e => setPassword(e.target.value)}
                                required />
                                <label>{t('password')} <span style={{color:'red'}}>*</span></label>
                            </div>
                            </div>
                        </div>
                        <div style={{marginTop:'-10px',marginBottom:'10px'}}>
                            { direction?
                                
                                <span style={{marginTop:'-10px',marginBottom:'10px'}}>
                                    <input type="checkbox" name="terms" required/>&nbsp;
                                    I accept the <a href="https://academy.lovush.com/terms-conditions/" target="_blank" rel="noopener noreferrer">Terms of use</a> and the 
                                    <a href="https://academy.lovush.com/privacy-policy/" target="_blank" rel="noopener noreferrer">&nbsp;Privacy policy</a>
                                </span>
                            :
                                <span style={{marginTop:'-10px',marginBottom:'10px'}}>
                                    <input type="checkbox" name="terms" required/>&nbsp;
                                    קראתי ואני מסכים/ה <a href="https://academy.lovush.com/terms-conditions/" target="_blank" rel="noopener noreferrer">לתנאי השימוש</a> ול 
                                    <a href="https://academy.lovush.com/privacy-policy/" target="_blank" rel="noopener noreferrer">&nbsp;מדיניות הפרטיות</a>
                                </span>
                            }
                        </div>

                        <div className="row justify-content-center">
                            <div className="col-12 col-sm-10 col-md-6">
                                <button type="submit" className="start_assesment">{t('signup')}</button>
                                <br></br>
                                { direction?
                                    <span style={{color:'grey',fontSize:'14px'}}>Already registered? <Link to="/login" style={{color:'grey'}}>Sign in</Link>
                                    </span>
                                :
                                    <span style={{color:'grey',fontSize:'14px'}}>כבר רשומים? <Link to="/login" style={{color:'grey'}}>התחברו</Link>
                                    </span>
                                }
                            </div>
                        </div>
                    </form>
                </div>
                </div>
            </div>
            </div>
        </div>
        <Footer />
        {
        direction?
            <ToastContainer />
        :
            <ToastContainer rtl/>
        }

    </div>
    )
    // }
}

export default Landing
