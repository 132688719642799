import React, {Fragment, useEffect, useState} from 'react';
import {BrowserRouter as Router, Route, Switch, Redirect} from 'react-router-dom';
import Landing from './components/Landing';
import Login from './components/Login';
import Gender from './components/Gender';
import Welcome from './components/Welcome';
import Question from './components/Question';
import Loading from './components/Loading';
import Result from './components/Result';
import NotFound from './components/NotFound';
import Logout from './components/Logout';
import Finished from './components/Finished';
import Super from './components/Super';
import SuperResult from './components/SuperResult';
import Signup from './components/Signup';
import i18n from "./i18n";
import { I18nextProvider, useTranslation } from 'react-i18next';


function App() {

  useEffect(() => {

   

    if(localStorage.getItem("language")==null || localStorage.getItem("language")==''){
      i18n.changeLanguage('he')
      localStorage.setItem('language','he')

    } else {
      i18n.changeLanguage(localStorage.getItem('language'))
      
    }
    if (localStorage.getItem('language') === 'he') {
      document.body.style.direction = "rtl";
    } else {
      document.body.style.direction = "ltr";
    }
  }, []);


  return (
    <I18nextProvider i18n={i18n}>
    <Router>
      <Fragment>
          <Switch>
            <Route exact path="/">
              <Redirect to="/home" component={Landing} />
            </Route>
            <Route exact path='/home' component={Landing} />
            <Route exact path='/login' component={Login} />
            <Route exact path='/gender' component={Gender} />
            <Route exact path='/Welcome' component={Welcome} />
            <Route exact path='/Question' component={Question} />
            <Route exact path='/Loading' component={Loading} />
            <Route exact path='/Result' component={Result} />
            <Route exact path='/Logout' component={Logout} />
            <Route exact path='/Finished' component={Finished} />
            <Route exact path='/Super' component={Super} />
            <Route exact path='/Superesult' component={SuperResult} />
            <Route exact path='/Signup' component={Signup} />
            <Route component={NotFound} />
          </Switch>
      </Fragment>
    </Router>
    </I18nextProvider>
  );
}

export default App;
