import React,{Fragment, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import Footer from './Footer'
import { useHistory } from 'react-router';
import jwt_decode from 'jwt-decode'
import parse from 'html-react-parser'
import { questions, update_client_answers, answers, client_answers} from './UserFunctions'
import { useTranslation } from 'react-i18next';


function Question() {

    const history = useHistory();
    const [question,setQuestion] = useState('')
    const [answerData,setAnswer] = useState('')
    const [questionId,setQuestionId] = useState('')
    const [questionNumber,setQuestionNumber] = useState('')
    const [sex,setSex] = useState('')
    const [option,setOption] = useState(0)
    const [userId,setUserId] = useState('')
    const [reload,setReload] = useState(0)
    const [disable,setDisable] = useState(false)
    const { t } = useTranslation();
    const [align, setAlign] = useState('')
    const [flag, setFlag] = useState(false)


    useEffect(() => {
        if(localStorage.getItem("usertoken")==null || localStorage.getItem("usertoken")==''){
            history.push(`/home`)
          }
    });

    
    useEffect(() => {
        let mounted = true;

        const token = localStorage.usertoken
        const decoded = jwt_decode(token)
            
        setUserId(decoded.id)
        
        
        const fetchData = () => {
            

            const idToPass = {
                userId : decoded.id,
            }


            client_answers(idToPass)
            .then(userData => {

                var temp = userData.data.number_q
                var data = {
                    id : decoded.id,
                    q_number: userData.data.number_q,
                    sex: userData.data.sex,
                    lang: localStorage.getItem('language')

                }



                if(temp==79)
                {
                    history.push(`/finished`)
                }else{
    
                    questions(data)
                    .then(userData => {
                        
                        if(userData)
                        {
                            setDisable(false);
                            setQuestion(userData.data.question)
                            setQuestionId(userData.data.id)
                            setQuestionNumber(userData.data.number)
                            setSex(userData.data.sex)
                            
                        }


                        answers({
                            id : userData.data.id,
                            sex
                        })
                        .then(userData => {
            
                            if(userData)
                            {
                                setAnswer(userData.response)
                            
                            }
            
                        })
                        .catch((error) => { 
                            console.log("Error! Something went wrong")
                        });
        

                    })
                    .catch((error) => { 
                        console.log("Error! Something went wrong")
                    });
        
 
                }
               
                

            })
            .catch((error) => { 
                console.log("Error! Something went wrong")
            });

            
        }

        if (localStorage.getItem('language') === 'he')
        {
            setAlign('right')
            setFlag(false)
        }
        else
        {
            setAlign('left')
            setFlag(true)
        }

        fetchData();
        return () => {
            mounted = false;
        };

    },[sex,reload]);



   


    const handleSubmit = async (event) => {
        event.preventDefault();

        setDisable(true);

        const data = {
            id : userId,
            answer: option,
            number_q: questionNumber+1,
            lang: localStorage.getItem('language')
        }

        update_client_answers(data)
        .then(userData => {

            if(userData)
            {
                setReload(reload+1)
                setOption(0)
            }

        })
        .catch((error) => { 
            console.log("Error!")
        });

    }

    return (
        <div className="questions_bg before_img Question">
            <div className="wrapper">
                <div className="container">
                    <div className="row">
                    <div className="col-12 text-center">
                        <div className="logo wow animate__fadeInLeft animate__animated">
                        <a href><img src="resources/images/logo.png" /></a>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="welcomeback">
                    <form onSubmit={handleSubmit}>
                    <div className="container">
                    <div className="row">
                        <div className="col-12 text-center">
                            <br></br>
                            <h5 style={{color:'black'}}><b>{t('qNumber')} {questionNumber+1} </b></h5>
                        <div className="ipade_screen text_desc mb-0">
                            <div className="ipade_inner wow animate__fadeInLeft animate__animated">
                            <div style={{textAlign:align}}>
                                
                                <p className={flag?'color_en':''}>{parse(question)}
                                </p>

                               
                                <ul className={'text-'+align} style={{color:'white',fontSize:'14px'}}>
                                    {
                                        answerData.length>0?
                                        answerData.map((ans)=>(
                                        <Fragment>
                                            <label htmlFor={ans.id} >
                                            <input type="radio" 
                                                name="answer"
                                                id={ans.id}
                                                value={ans.id}
                                                checked={option == ans.id}
                                                onChange={e => setOption(e.target.value)}
                                                
                                            />
                                            &nbsp;{ans.text}</label>

                                            <br></br>
                                        </Fragment>
                                        ))
                                        :null
                                    }
                                </ul>
                                <div className="right_arrow">
                                {flag?
                                    <>
                                    
                                    <Link to="/logout" className="prev" style={{left:'-75px'}}><i className="fas fa-arrow-circle-left"
                                        style={{left:'6px',position:'relative'}} />{t('goOut')}</Link>
                                    <button type="submit" style={{border:'0',padding:'0',cursor:'pointer',background:'none',right:'-90px'}} className="next"
                                    disabled={disable}
                                    ><i className="fas fa-arrow-circle-right" />{t('continue')}</button>
                                    </>
                                :
                                    <>
                                    <button type="submit" style={{border:'0',padding:'0',cursor:'pointer',background:'none'}} className="prev"
                                    disabled={disable}
                                    ><i className="fas fa-arrow-circle-left" />{t('continue')}</button>
                                    <Link to="/logout" className="next"><i className="fas fa-arrow-circle-right" />{t('goOut')}</Link>
                                    </>
                                }
                                
                                </div>
                            </div>
                            </div>
                        </div>
                        {/* <div className="smallbtns custom_btn">
                            <Link to="/question">המשך</Link>
                            <Link to="/logout">צא</Link>
                        </div> */}
                        </div>
                    </div>
                    </div>
                    </form>

                </div>
            </div>
        <Footer />
        </div>
    )
}

export default Question
