import React,{useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import Footer from './Footer'
import { useHistory } from 'react-router';
import { update_gender } from './UserFunctions'
import jwt_decode from 'jwt-decode'
import { useTranslation } from 'react-i18next';

function Gender() {

    const history = useHistory();
    const [gender,setGender] = useState('male')
    const { t } = useTranslation();
    const [direction, setDirection] = useState('')
    const [flag, setFlag] = useState(false)

    useEffect(() => {
         
        if(localStorage.getItem("usertoken")==null || localStorage.getItem("usertoken")==''){
            history.push(`/home`)
        }
        
        if (localStorage.getItem('language') === 'he')
        {
            setDirection('rtl')
            setFlag(false)
        }
        else
        {
            setDirection('ltr')
            setFlag(true)
        }
    });

    const handleSubmit = async (event) => {
        event.preventDefault();


            const token = localStorage.usertoken
            const decoded = jwt_decode(token)
                
            const data = {
            id : decoded.id,
            sex: gender
            }


            update_gender(data)
            .then(userData => {

                if(userData)
                {
                    history.push(`/question`)
                }

            })
            .catch((error) => { 
                console.log("Error! Something went wrong")
            });
    }

    return (
        <div className="before_img Gender">
            <div className="wrapper">
                <div className="container">
                    <div className="row">
                    <div className="col-12 text-center">
                        <div className="logo wow animate__fadeInLeft animate__animated">
                        <a href><img src="resources/images/logo.png" /></a>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="container">
                    <form onSubmit={handleSubmit}>
                    <div className="row">
                    <div className="col-12">
                        <div className="text_desc text-center wow animate__fadeInLeft animate__animated">
                        <p>          
                            {t('wlc')}
                        <br></br>
                            {t('genderIntro')} 
                        </p>
                        </div>
                        <div className="custom_gender text-center wow animate__fadeInLeft animate__animated">
                        <h3>{t('iAm')}</h3>
                        <div className={flag? 'custom_radio gender_fix': 'custom_radio'}>
                            <input type="radio" id="male" name="selector" 
                            onChange={e => setGender(e.target.value)}
                            value="male"
                            defaultChecked />
                            <label htmlFor="male" class={flag ?'radio_en':''}>{t('male')}</label>
                            <div className="check" />
                        </div>
                        <div className={flag? 'custom_radio gender_fix': 'custom_radio'}>
                            <input type="radio" id="female" name="selector" 
                            onChange={e => setGender(e.target.value)}
                            value="female"
                            />
                            <label htmlFor="female" class={flag ?'radio_en':''}>{t('female')}</label>
                            <div className="check" />
                        </div>
                        </div>
                        <div className="text_desc text-center m-0 wow animate__fadeInLeft animate__animated">
                        <p>{t('genderInfo')}</p>
                        </div>
                        <div className="smallbtns custom_btn text-center" style={{direction:direction}}>
                            <button type="submit" class="smallbtnscustom">{t('getStarted')}</button>
                            <Link to="/logout">{t('goOut')}</Link>
                        </div>
                    </div>
                    </div>
                    </form>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Gender
