import React,{useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import Footer from './Footer';
import { client_answers } from './UserFunctions'
import { useHistory } from 'react-router';
import jwt_decode from 'jwt-decode'
import { useTranslation } from 'react-i18next';


function Welcome() {

    const history = useHistory();
    const [url,setUrl] = useState('/question')
    const [welcomeText,setWelcomeText] = useState()
    const { t } = useTranslation();
    const [direction, setDirection] = useState('')


    useEffect(() => {
         
        if(localStorage.getItem("usertoken")==null || localStorage.getItem("usertoken")==''){
            history.push(`/home`)
        }else{
            const token = localStorage.usertoken
            const decoded = jwt_decode(token)
            const code = decoded.code

            if(code==0)
                history.push(`/gender`)
            setWelcomeText(code)
                
            const idToPass = {
                userId : decoded.id,
            }


            client_answers(idToPass)
            .then(userData => {

               if(userData.data.number_q=="" || userData.data.number_q==0)
               {
                setUrl('/gender')
               }

            })
            .catch((error) => { 
                console.log("Error! Something went wrong")
            });
        }

        if (localStorage.getItem('language') === 'he')
        {
            setDirection('rtl')
        }
        else
        {
            setDirection('ltr')
        }
        
    },[url]);


    return (
        <div className="Welcome">
            <div className="wrapper">
                <div className="container">
                    <div className="row">
                    <div className="col-12 text-center">
                        <div className="logo wow animate__fadeInLeft animate__animated">
                        <a href><img src="resources/images/logo.png" /></a>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="welcomeback">
                    <div className="container">
                    <div className="row">
                        <div className="col-12 text-center">
                        <div className="back_big_btn custom_btn wow animate__fadeInLeft animate__animated">
                            
                                {welcomeText>0?
                                    <Link>{t('welcomeBack')}</Link>
                                :
                                null
                                    //<Link>{t('welcome')}</Link>
                                }
                            
                        </div>
                        <br></br>
                        
                        <div className="smallbtns custom_btn" style={{direction:direction}}>
                            
                            <Link to={url}>{t('getStarted')}</Link>
                            <Link to="/logout">{t('goOut')}</Link>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Welcome
